import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import AccordionFaqs from './AccordionFaqs';

export const Faqs = () => {
    return (
        <div>
        <section className="mdl-hero-in">
            <Container>
            <Row>
                <Col md={12} className="col-md-12 d-flex justify-content-center">
                <h1 className="title">Preguntes freqüents</h1>
                </Col>
            </Row>
            </Container>
        </section>
        <section className="mdl-hero-in bg-white">
            <Container style={{ maxWidth: '800px' }}>
            <Row>
                <Col md={12} className="d-flex justify-content-center flex-column align-items-start">
                    <AccordionFaqs />
                </Col>
            </Row>
            </Container>
        </section>
        
        </div>
    );
};