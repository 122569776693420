import React, { useState } from "react";
import { Col, Container, Row, Form, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";

import "./footer.scss";
import apiBaseUrl from "../../../config/api.js";

function Footer() {
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [policy, setPolicy] = useState(false);

  const handleSubscribe = (e) => {
    e.preventDefault();
    if (email) {
      axios.post(`${apiBaseUrl}/newsletter/store/`, {
        email,
        privacy_policy: true,
      });
      setShowModal(true);
      setEmail("");
      setPolicy(false);
    } else {
      alert("Si us plau, introdueix un correu elentrònic vàlid.");
    }
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <footer className="footer pt-5 pb-5">
        <Container>
          <Row>
            <Col md={3}>
              <h4>fundwave</h4>
              <ul>
                <li>
                  <Link to="about" aria-label={`Veure Qui som`} >Qui som</Link>
                </li>
                <li>
                  <Link to="/contact" aria-label={`Veure Contacte`} >Contacte</Link>
                </li>
                <li>
                  <Link to="/faqs" aria-label={`Veure preguntes freqüents`} >FAQ</Link>
                </li>
              </ul>
            </Col>
            <Col md={3}>
              <h4>més informació</h4>
              <ul>
                <li>
                  <Link to="legal-cookies" aria-label={`Veure Política de cookies`} >Política de cookies</Link>
                </li>
                <li>
                  <Link to="legal-privacy" aria-label={`Veure Política de privacitat`} >Política de privacidad</Link>
                </li>
                <li>
                  <Link to="legal-advice" aria-label={`Veure Avís legal`} >Avís legal</Link>
                </li>
              </ul>
              <p>
                Copyright © 2024 fundwave. <br /> Tots els drets reservats.
              </p>
            </Col>
            <Col md={6}>
              <h5>Subscriu-te a la newsletter</h5>
              <p>Rep les últimes noticies i actualitzacions de FundWave.</p>
              <Form onSubmit={handleSubscribe}>
                <Form.Group controlId="newsletterEmail">
                  <Form.Control
                    type="email"
                    placeholder="Introdueix el teu correu electrònic"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="mb-3"
                  />
                </Form.Group>
                <Form.Group controlId="newsletterEmail">
                  <Form.Check
                    type="checkbox"
                    label={
                      <>
                        He llegit i accepto la{" "}
                        <Link
                          to="/legal-privacy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          política de privacitat
                        </Link>
                        .
                      </>
                    }
                    checked={policy}
                    onChange={(e) => setPolicy(e.target.checked)}
                    required
                    className="mb-3"
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="mt-2">
                  Subscriure'm
                </Button>
              </Form>
              <div className="social-icons mt-3">
                <a
                  href="https://www.instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="me-3"
                >
                  <i className="bi bi-instagram fs-5"></i>
                </a>
                <a
                  href="https://www.twitter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="me-3"
                >
                  <i className="bi bi-twitter fs-5"></i>
                </a>
                <a
                  href="https://www.youtube.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="bi bi-youtube fs-5"></i>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>

      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleCloseModal}
      >
        <Modal.Body className="p-md-5 text-center">
          <Button
            className="position-absolute right-0 top-0"
            variant=""
            style={{ right: "0" }}
            onClick={handleCloseModal}
          >
            <i className="bi bi-x-circle"></i>
          </Button>
          <h2 className="">Gràcies per subscriure't a la nostra newsletter!</h2>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Footer;
