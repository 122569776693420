import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import apiBaseUrl from "../../config/api";

// Crear el context
export const ProjectContext = createContext();

// Proveïdor del context
export const ProjectProvider = ({ children }) => {
  const [projects, setProjects] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/projects`);
        const projectsData = response.data.projects;
        setProjects(projectsData);

        // Crear un array únic d'objectes amb category i category_id
        const uniqueCategories = projectsData.reduce((acc, project) => {
          if (!acc.some((cat) => cat.category_id === project.category_id)) {
            acc.push({
              category: project.category,
              category_id: project.category_id,
            });
          }
          return acc;
        }, []);
        setCategories(uniqueCategories);
      } catch (err) {
        setError("Error al carregar els projectes");
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  return (
    <ProjectContext.Provider
      value={{ projects, setProjects, categories, loading, error }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

// Hook personalitzat per utilitzar el context de projectes
export const useProjects = () => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error(
      "useProjects ha de ser utilitzat dins d'un ProjectProvider"
    );
  }
  return context;
};
