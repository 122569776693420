import React, { useState } from "react";
import axios from "axios";
import apiBaseUrl from "../../../config/api";
import { useUser } from "../../../context/UserContext";

function Contribute({ projectId }) {
  const [amount, setAmount] = useState("");
  const [message, setMessage] = useState("");
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { user, token } = useUser();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess(null);
    setError(null);

    if (!amount || parseFloat(amount) <= 0) {
      setError("Si us plau, introdueix una quantitat vàlida.");
      return;
    }

    try {
      setLoading(true);

      await axios.post(
        `${apiBaseUrl}/contributions/add`,
        {
          project_id: projectId,
          amount: parseFloat(amount),
          message: message || null,
          is_anonymous: isAnonymous ? 1 : 0,
          fund_user_id: user.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSuccess(
        "Donació realitzada amb èxit. Gràcies per la teva contribució!"
      );
      setAmount("");
      setMessage("");
      setIsAnonymous(false);
    } catch (err) {
      console.error("Error en realitzar la donació:", err);
      setError("Hi ha hagut un problema. Torna-ho a intentar més tard.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mt-5">
      <h5>Fes la teva donació</h5>
      {success && <div className="alert alert-success">{success}</div>}
      {error && <div className="alert alert-danger">{error}</div>}
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="amount" className="form-label">
            Quantitat (€)
          </label>
          <input
            type="number"
            id="amount"
            className="form-control"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder="Introdueix la quantitat"
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="message" className="form-label">
            Missatge (opcional)
          </label>
          <textarea
            id="message"
            className="form-control"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Escriu un missatge..."
            rows="3"
          />
        </div>
        <div className="form-check mb-3">
          <input
            type="checkbox"
            id="isAnonymous"
            className="form-check-input"
            checked={isAnonymous}
            onChange={(e) => setIsAnonymous(e.target.checked)}
          />
          <label htmlFor="isAnonymous" className="form-check-label">
            Fer aquesta donació de manera anònima
          </label>
        </div>
        <button type="submit" className="btn btn-primary" disabled={loading}>
          {loading ? "Processant..." : "Donar"}
        </button>
      </form>
    </div>
  );
}

export default Contribute;
