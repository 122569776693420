import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./about.scss";

export const About = () => {
  return (
    <div>
      <section className="mdl-hero-in">
        <Container>
          <Row>
            <Col md={12} className="col-md-12 d-flex justify-content-center">
              <h1 className="title">Qui som</h1>
            </Col>
          </Row>
        </Container>
        
      </section>
      <section className="mdl-hero-in bg-white">
      <Container style={{ maxWidth: '800px' }}>
          <Row>
            <Col md={6} className="d-flex justify-content-center text-center">
              <img
                  src="/imgs/aboutus/team.jpg"
                  alt="Qui som"
                  className="img-fluid rounded-circle mb-5 mb-md-0"
                />
            </Col>
            <Col md={6} className="d-flex justify-content-center flex-column align-items-start">
              <p><strong>FundWave</strong> és un projecte de crowdfunding creat per un equip de quatre estudiants apassionats pel desenvolupament web i amb l'objectiu de canviar la manera en què les persones financen les seves idees i projectes. A través de la nostra plataforma, oferim als emprenedors, creatius i organitzacions la possibilitat d'obtenir el suport financer necessari per fer realitat els seus somnis.</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="mdl-hero-in">
        <Container>
          <Row>
            <Col md={12} className="col-md-12 d-flex justify-content-center">
              <h2 className="title mb-5">El Nostre Equip</h2>
            </Col>
            <Col md={3} className="d-flex align-items-center flex-column">
              <img
                src="/imgs/aboutus/avatar.png"
                alt="Avatar"
                className="img-fluid rounded-circle mb-5 mb-md-0"
                style={{ maxWidth: '150px' }}
              />
              <h3 className="text-center">Victor Aguado</h3>
              <h4 className="text-center">BackEnd</h4>
              <p className="text-center">Expert en Laravel i gestió de bases de dades amb MySQL, és responsable de construir la lògica i l'arquitectura del sistema que fa que FundWave sigui una plataforma segura i eficient.</p>
            </Col>
            <Col md={3} className="d-flex align-items-center flex-column">
              <img
                src="/imgs/aboutus/avatar.png"
                alt="Avatar"
                className="img-fluid rounded-circle mb-5 mb-md-0"
                style={{ maxWidth: '150px' }}
              />
              <h3 className="text-center">Oriol Hilari</h3>
              <h4 className="text-center">FrontEnd</h4>
              <p className="text-center">Amb experiència en frameworks com React, treballa en oferir una experiència d'usuari fluïda i intuïtiva a través de dissenys interactius i responsius.</p>
            </Col>
            <Col md={3} className="d-flex align-items-center flex-column">
              <img
                src="/imgs/aboutus/avatar.png"
                alt="Avatar"
                className="img-fluid rounded-circle mb-5 mb-md-0"
                style={{ maxWidth: '150px' }}
              />
              <h3 className="text-center">Nàdia Núñez</h3>
              <h4 className="text-center">FrontEnd</h4>
              <p className="text-center">Especialitzat en HTML, CSS i JavaScript, s'encarrega de crear una interfície atractiva i accessible per als usuaris de FundWave.</p>
            </Col>
            <Col md={3} className="d-flex align-items-center flex-column">
              <img
                src="/imgs/aboutus/avatar.png"
                alt="Avatar"
                className="img-fluid rounded-circle mb-5 mb-md-0"
                style={{ maxWidth: '150px' }}
              />
              <h3 className="text-center">Miguel Pujazón</h3>
              <h4 className="text-center">BackEnd</h4>
              <p className="text-center">Treballa en la integració de funcionalitats clau, com la gestió de transaccions i la seguretat, assegurant-se que la plataforma respongui a les necessitats de tots els usuaris.</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="mdl-hero-in bg-white">
        <Container style={{ maxWidth: '800px' }}>
          <Row className="flex-row-reverse">
            <Col md={6} className="d-flex justify-content-center text-center">
              <img
                  src="/imgs/aboutus/visio.jpg"
                  alt="La nostra visió"
                  className="img-fluid rounded-circle mb-5 mb-md-0"
                />
            </Col>
            <Col md={6} className="d-flex justify-content-center flex-column align-items-start">
              <h2 className="title mb-5">La Nostra Visió</h2>
              <p>Crear una plataforma accessible, segura i eficient que permeti als projectes innovadors rebre el suport necessari per créixer.<br /> Creiem en el poder de la comunitat i en com el crowdfunding pot transformar el futur de les idees.</p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="mdl-hero-in">
        <Container style={{ maxWidth: '800px' }}>
          <Row>
            <Col md={6} className="d-flex justify-content-center text-center">
              <img
                  src="/imgs/aboutus/missio.jpg"
                  alt="La nostra missió"
                  className="img-fluid rounded-circle mb-5 mb-md-0"
                />
            </Col>
            <Col md={6} className="d-flex justify-content-center flex-column align-items-start">
              <h2 className="title mb-5">La Nostra Missió</h2>
              <p>Facilitar la connexió entre <strong>emprenedors</strong> i <strong>persones</strong> interessades a donar suport a causes i projectes que tinguin un impacte positiu en la societat.</p><p>A FundWave, busquem oferir un espai de confiança on les idees es converteixin en realitat.</p>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};
