import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import apiBaseUrl from "../../config/api";
import { Container, Row, Col } from "react-bootstrap";

function SearchResults() {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchTerm = searchParams.get("search");

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${apiBaseUrl}/projects/search`, {
          params: { keyword: searchTerm },
        });
        setResults(response.data);
      } catch (err) {
        console.error(
          "Error en la cerca:",
          err.response?.data || err.message || err
        );
        setError(
          err.response?.data?.message || "Hi ha hagut un problema amb la cerca."
        );
      } finally {
        setLoading(false);
      }
    };

    if (searchTerm) {
      fetchSearchResults();
    }
  }, [searchTerm]);

  if (loading) {
    return <div className="text-center mt-5">Carregant...</div>;
  }

  if (error) {
    return <div className="alert alert-danger mt-5">{error}</div>;
  }

  return (
    <Container className="mt-5">
      <h2 className="mb-4">Resultats per a: "{searchTerm}"</h2>
      {results.length > 0 ? (
        <Row>
          {results.map((project) => (
            <Col md={3} className="mb-5" key={project.id}>
              <div className="mdl-project__it bg-white overflow-hidden pb-5 position-relative h-100">                
                <img
                  src={project.img_url}
                  className="img-fluid mb-5 mb-md-3"
                  alt={project.title}
                  style={{ height: "200px", objectFit: "cover" }}
                />
                <span className="tag mx-3 position-absolute">
                  #{project.category}
                </span>
                <h3 className="mx-3">{project.title}</h3>
                <p className="mx-3">
                  {project.description.substring(0, 100)}...
                </p>
                <p className="mx-3">
                  <strong>Objectiu:</strong>{" "}
                  {project.financial_goal.toLocaleString()}€
                </p>
                <Link
                  to={`/project/${project.id}`}
                  className="position-absolute top-0 start-0 w-100 h-100"
                  aria-label={`Veure ${project.title}`}
                  >
                </Link>
              </div>
            </Col>
          ))}
        </Row>
      ) : (
        <div className="alert alert-info">No s'han trobat resultats.</div>
      )}
    </Container>
  );
}

export default SearchResults;
