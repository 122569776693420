import {Accordion} from 'react-bootstrap';

function AccordionFaqs() {
  return (
    <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
            <Accordion.Header>Què és el crowdfunding i com funciona?</Accordion.Header>
            <Accordion.Body>
            El crowdfunding és una forma de finançament col·lectiu en què moltes persones aporten petites quantitats de diners per donar suport a un projecte o idea. Els projectes poden ser de tot tipus, des d’emprenedories fins a causes socials. A la nostra plataforma, els usuaris poden crear campanyes i rebre contribucions d'altres persones interessades en donar suport a les seves iniciatives.
            </Accordion.Body>
        </Accordion.Item>
        
        <Accordion.Item eventKey="1">
            <Accordion.Header>Com puc crear una campanya de crowdfunding?</Accordion.Header>
            <Accordion.Body>
            Crear una campanya a la nostra plataforma és molt senzill. Només cal registrar-se, omplir la informació bàsica del teu projecte, establir una meta de finançament i definir les recompenses per als teus patrocinadors. Un cop fet això, podràs llançar la campanya i començar a rebre aportacions d'altres persones interessades en el teu projecte.
            </Accordion.Body>
        </Accordion.Item>
        
        <Accordion.Item eventKey="2">
            <Accordion.Header>Què passa si no arribo a l'objectiu de finançament?</Accordion.Header>
            <Accordion.Body>
            Si no arribes a l'objectiu de finançament en el temps establert, existeixen dues opcions depenent del model que hagis triat. En el model "Tot o res", si no arribes a l'objectiu, els fons no es cobraran als patrocinadors. En el model "Flexible", els fons es recullen independentment de si arribes o no a la teva meta, tot i que et recomanem establir metes realistes per assegurar l'èxit de la teva campanya.
            </Accordion.Body>
        </Accordion.Item>
        
        <Accordion.Item eventKey="3">
            <Accordion.Header>Com puc donar suport a una campanya?</Accordion.Header>
            <Accordion.Body>
            Donar suport a una campanya és molt fàcil. Només cal navegar pels projectes disponibles, escollir el que més t'interessi i seleccionar l'import que vols aportar. També podràs triar alguna recompensa o benefici si el creador de la campanya ha definit alguna. Els pagaments es realitzen de manera segura a través de la nostra plataforma.
            </Accordion.Body>
        </Accordion.Item>
        
        <Accordion.Item eventKey="4">
            <Accordion.Header>És segura la plataforma per fer transaccions?</Accordion.Header>
            <Accordion.Body>
            Sí, la nostra plataforma utilitza els més alts estàndards de seguretat en línia per protegir les teves dades i transaccions. Tots els pagaments es processen a través de passarel·les de pagament segures i encriptades, la qual cosa garanteix que la teva informació personal i financera estigui protegida en tot moment.
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>

  );
}

export default AccordionFaqs;