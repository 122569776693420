import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Form, Alert } from "react-bootstrap";
import { Formik, Field, ErrorMessage, Form as FormikForm } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { UserContext } from "../../context/UserContext";
import axios from "axios";
import apiBaseUrl from "../../config/api";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Introdueix un correu electrònic vàlid")
    .required("El correu electrònic és obligatori"),
  password: Yup.string()
    .min(6, "La contrasenya ha de tenir almenys 6 caràcters")
    .required("La contrasenya és obligatòria"),
});

const LoginPage = () => {
  const { user, login } = useContext(UserContext); // Accés al context d'usuari
  const navigate = useNavigate();
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [alert, setAlert] = useState({ show: false, type: "", message: "" }); // Estat per a les alertes

  useEffect(() => {
    if (user) {
      navigate("/profile"); // Redirigir si ja està autenticat
    }
  }, [user, navigate]);

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    if (!isResettingPassword) {
      try {
        const result = await login(values.email, values.password);

        if (!result.ok) {
          const errorData = await result.json();
          setErrors(errorData.message || "Credencials no vàlides");
          return;
        }
        // Guardar al localStorage (redundància per si el context no ho fa)
        if (result && result.token && result.user) {
          localStorage.setItem("token", result.token);
          localStorage.setItem("user", JSON.stringify(result.user));
        }
        
        navigate("/profile"); // Redirigir després d'iniciar sessió
      } catch (error) {
        setErrors({ email: "Credencials no vàlides." });
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        await axios.post(`${apiBaseUrl}/user/reset-password`, {
          email: values.email,
        });

        setAlert({
          show: true,
          type: "success",
          message: "Correu enviat amb èxit. Redirigint...",
        });

        // Redirigir després de 2 segons
        setTimeout(() => {
          setAlert({
            show: false,
            type: "success",
            message: "Correu enviat amb èxit. Redirigint...",
          });
          setIsResettingPassword(false);
        }, 2000);
      } catch (error) {
        setErrors({ email: "Credencials no vàlides" });
      }
    }
  };

  return (
    <Container
      className="mdl-standard d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      {/* Alerta flotant */}
      {alert.show && (
        <Alert
          variant={alert.type}
          className="position-fixed top-0 start-50 translate-middle-x mt-3 text-center w-50"
          style={{ zIndex: 1050 }}
          onClose={() => setAlert({ show: false })}
          dismissible
        >
          {alert.message}
        </Alert>
      )}

      <Row className="w-100 d-flex justify-content-center">
        <Col md={{ span: 5 }}>
          <h2 className="text-center mb-4">
            {isResettingPassword ? "Recuperar contrasenya" : "Iniciar Sessió"}
          </h2>
          <Card
            className="rounded-5"
            style={{ boxShadow: "10px 10px 0px #01403A" }}
          >
            <Card.Body className="position-relative p-4 p-xl-5">
              <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={
                  isResettingPassword
                    ? validationSchema.pick(["email"])
                    : validationSchema
                }
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <FormikForm>
                    <Form.Group controlId="email" className="mb-3">
                      <Form.Label>Correu electrònic</Form.Label>
                      <Field name="email" type="email" as={Form.Control} />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>

                    {!isResettingPassword && (
                      <Form.Group controlId="password" className="mb-3">
                        <Form.Label>Contrasenya</Form.Label>
                        <Field
                          name="password"
                          type="password"
                          as={Form.Control}
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    )}

                    <Button
                      type="submit"
                      className="w-100"
                      variant="primary"
                      disabled={isSubmitting}
                    >
                      {isResettingPassword
                        ? "Restableix la contrasenya"
                        : "Iniciar Sessió"}
                    </Button>
                  </FormikForm>
                )}
              </Formik>
              {!isResettingPassword ? (
                <div className="text-center mt-3">
                  <Link to="/signup" className="text-primary btn btn-link"  aria-label={`Veure Registre`}>
                    ¿Ets nou? Registra't ara!
                  </Link>
                  <div className="mt-2">
                    <Button
                      variant="link"
                      onClick={() => setIsResettingPassword(true)}
                      className="text-secondary"
                    >
                      Has oblidat la contrasenya?
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="text-center mt-3">
                  <Button
                    variant="link"
                    onClick={() => setIsResettingPassword(false)}
                    className="text-secondary"
                  >
                    Torna a iniciar sessió
                  </Button>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
