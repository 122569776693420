import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Alert,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useProjects } from "../../context/ProjectContext";
import { useUser } from "../../context/UserContext";
import apiBaseUrl from "../../config/api";
import { cloud_name, preset_name } from "../../config/cloudinary";

const CreateProjectPage = () => {
  const { categories } = useProjects(); // Obtenir les categories del context
  const { token, user } = useUser(); // Obtenir el token de l'usuari
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    financial_goal: "",
    category_id: "",
    deadline: "",
    img: null,
  });
  const [alert, setAlert] = useState({ show: false, type: "", message: "" }); // Estat per a les alertes
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Redirigir l'usuari si no està autenticat
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  // Gestionar els canvis en el formulari
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData({ ...formData, img: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const uploadImage = async (file) => {
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", preset_name);
    data.append("folder", "projects_assets");
    setLoading(true);
    try {
      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`,
        data
      );
      return response.data.secure_url;
    } catch (error) {
      console.error("Error al pujar la imatge:", error);
      setLoading(false);
    }
  };

  // Gestionar l'enviament del formulari
  const handleSubmit = async (e) => {
    e.preventDefault();
    setAlert({ show: false, type: "", message: "" });

    // Validar camps obligatoris
    if (
      !formData.title ||
      !formData.description ||
      !formData.financial_goal ||
      !formData.category_id ||
      !formData.deadline
    ) {
      setAlert({
        show: true,
        type: "danger",
        message: "Si us plau, completa tots els camps obligatoris.",
      });
      return;
    }

    try {
      setLoading(true);

      const projectData = {
        title: formData.title,
        description: formData.description,
        financial_goal: formData.financial_goal,
        category_id: formData.category_id,
        deadline: formData.deadline,
      };

      if (formData.img) {
        const urlImg = await uploadImage(formData.img);
        projectData.imgUrl = urlImg;
      }

      // Enviar l'objecte com JSON
      await axios.post(`${apiBaseUrl}/projects/create`, projectData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      setAlert({
        show: true,
        type: "success",
        message: "Projecte creat amb èxit. Redirigint...",
      });

      // Redirigir a la pàgina del perfil després de 2 segons
      setTimeout(() => {
        navigate("/profile");
      }, 2000);
    } catch (err) {
      console.error("Error al crear el projecte:", err);
      setAlert({
        show: true,
        type: "danger",
        message:
          "Hi ha hagut un problema al crear el projecte. Torna-ho a intentar.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="mt-5 mb-5">
      {/* Alerta flotant */}
      {alert.show && (
        <Alert
          variant={alert.type}
          className="position-fixed top-0 start-50 translate-middle-x mt-3 text-center w-50"
          style={{ zIndex: 1050 }}
          onClose={() => setAlert({ show: false })}
          dismissible
        >
          {alert.message}
        </Alert>
      )}

      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="">
            <Card.Body className="p-4 p-md-5">
              <h3 className="mb-4 text-center">Crear projecte</h3>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Títol</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Introdueix el títol del projecte"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Objetiu econòmic (€)</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Introdueix import"
                        name="financial_goal"
                        value={formData.financial_goal}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Categoria</Form.Label>
                      <Form.Select
                        name="category_id"
                        value={formData.category_id}
                        onChange={handleChange}
                      >
                        <option value="">Selecciona una categoria</option>
                        {categories.map((categoryObj) => (
                          <option
                            key={categoryObj.category_id}
                            value={categoryObj.category_id}
                          >
                            {categoryObj.category}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Data límit</Form.Label>
                      <Form.Control
                        type="date"
                        name="deadline"
                        value={formData.deadline}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Imatge del projecte</Form.Label>
                      <Form.Control
                        type="file"
                        name="img"
                        accept="image/*"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Descripció</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Descripció"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Button
                      type="submit"
                      variant="primary"
                      className="w-100"
                      disabled={loading}
                    >
                      {loading ? "Creant..." : "Crear projecte"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateProjectPage;
