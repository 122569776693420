import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Intro = () => {
  return (
    <section className="mdl-intro pt-5 pb-5">
      <Container>
        <Row className="mb-5 row align-items-center d-flex">
          <Col md={6}>
            <h2>Per què Fundwave?</h2>
            <p>
              En FundWave, connectem persones innovadores amb persones que
              creuen en el poder de les bones idees. Descobreix i dona suport a
              projectes que importen!
            </p>
          </Col>
          <Col md={6}>
            <img
              src="/imgs/img-intro.jpg"
              alt="Imagen de equipo"
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Intro;
