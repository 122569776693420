import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ProjectContext } from "../../context/ProjectContext";
import { Container, Row, Col } from "react-bootstrap";

function ProjectList() {
  const { projects, categories, loading, error } = useContext(ProjectContext);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const location = useLocation(); // Per llegir els paràmetres de la URL
  const navigate = useNavigate(); // Per actualitzar la URL dinàmicament

  // Llegir categories des de la URL en carregar
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const categoryParams = params.get("category");
    if (categoryParams) {
      const categoriesFromURL = categoryParams.split(",");
      setSelectedCategories(categoriesFromURL);
    } else {
      setSelectedCategories([]);
    }
  }, [location.search]);

  // Filtrar projectes segons les categories seleccionades
  useEffect(() => {
    if (selectedCategories.length === 0) {
      setFilteredProjects(projects);
    } else {
      const filtered = projects.filter((project) =>
        selectedCategories.includes(project.category)
      );
      setFilteredProjects(filtered);
    }
  }, [projects, selectedCategories]);

  // Gestionar selecció i deselecció de categories
  const handleCategoryClick = (category) => {
    const isSelected = selectedCategories.includes(category);
    const updatedCategories = isSelected
      ? selectedCategories.filter((c) => c !== category) // Deseleccionar
      : [...selectedCategories, category]; // Seleccionar

    setSelectedCategories(updatedCategories);

    // Actualitzar la URL amb les categories seleccionades
    const params = new URLSearchParams();
    if (updatedCategories.length > 0) {
      params.set("category", updatedCategories.join(","));
    }
    navigate(`?${params.toString()}`);
  };

  // Esborrar filtres
  const clearFilters = () => {
    setSelectedCategories([]);
    navigate("/projects"); // Esborrar paràmetres de la URL
  };

  if (loading) {
    return <div className="text-center mt-5">Carregant...</div>;
  }

  if (error) {
    return <div className="alert alert-danger mt-5">{error}</div>;
  }

  return (
    <Container className="mt-5">
      <Row>
        <Col md={12}>
            <h1 className="mb-4">Llista de projectes</h1>
          <p>
            Descobreix tots els projectes publicats a FundWave, una plataforma que connecta persones amb idees transformadores amb aquells que volen fer-les realitat. Filtra per categories per trobar iniciatives que s'ajustin als teus interessos, des de projectes d'educació fins a iniciatives mediambientals i socials. Uneix-te a la comunitat que impulsa el canvi i contribueix a donar suport als projectes que importen.
          </p>
          {/* Filtres de categories */}
          <div className="mb-4">
            <div className="d-flex flex-wrap align-items-center">
              {categories.map((categoryObj) => (
                <button
                  key={categoryObj.category_id} // Usa category_id com a clau única
                  className={`btn me-2 mb-2 ${
                    selectedCategories.includes(categoryObj.category) // Verifica pel nom de la categoria
                      ? "btn-secondary"
                      : "btn-outline-secondary"
                  }`}
                  onClick={() => handleCategoryClick(categoryObj.category)} // Passa el nom de la categoria
                >
                  {categoryObj.category} {/* Mostra el nom de la categoria */}
                </button>
              ))}
              {/* Botó per esborrar filtres */}
              {selectedCategories.length > 0 && (
                <button className="btn btn-danger ms-3 mb-2" onClick={clearFilters}>
                  Esborrar <i class="bi bi-x-circle"></i>
                </button>
              )}
            </div>
          </div>
        </Col>
      </Row>
      

      {/* Llistat de projectes */}
      <Row>
        {filteredProjects.map((project) => (
          <Col md={3} className="mb-5" key={project.id}>
            <div className="mdl-project__it bg-white overflow-hidden pb-5 position-relative h-100">
              <img
                src={project.img_url}
                alt={project.title}
                className="img-fluid mb-5 mb-md-3"
              />
              <span className="tag mx-3 position-absolute">
                #{project.category}
              </span>
              <h3 className="mx-3">{project.title}</h3>
              <p className="mx-3">
                {project.description.length > 100
                  ? `${project.description.substring(0, 100)}...`
                  : project.description}
              </p>
              <p className="mx-3">
                <strong>Objectiu: </strong>
                {project.financial_goal.toLocaleString()}€
              </p>
              <Link
                to={`/project/${project.id}`}
                className="position-absolute top-0 start-0 w-100 h-100"
                aria-label={`Veure ${project.title}`}
              >
              </Link>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default ProjectList;
