import React, { useState, useEffect, useContext, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import { ProjectContext } from "../../context/ProjectContext";
import apiBaseUrl from "../../config/api";
import { cloud_name, preset_name } from "../../config/cloudinary";

const ProfilePage = () => {
  const { token, logout } = useContext(UserContext);
  const { setProjects } = useContext(ProjectContext);
  const [user, setUser] = useState(null);
  const [projectList, setProjectList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [passwordData, setPasswordData] = useState({
    password: "",
    confirmPassword: "",
  });
  //changeProfile s'usa com a dependència del useEffect quan es canvia la imatge de perfil
  const [changeProfile, setChangeProfile] = useState("");
  const [passwordMessage, setPasswordMessage] = useState("");
  const [projectToDelete, setProjectToDelete] = useState(null);

  //Estat per mostrar o no el "modal" de missatge confirmació abans d'eliminar projecte
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setProjectToDelete(id);
    setShow(true);
  };

  const navigate = useNavigate();

  const fetchUserData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiBaseUrl}/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser(response.data.user);
      setProjectList(
        response.data.user.projects.filter(
          (project) => project.status !== "deleted"
        )
      );
    } catch (err) {
      setError("Error en carregar la informació de l'usuari.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      fetchUserData();
    } else {
      setError("No s'ha trobat un token d'autenticació.");
      setLoading(false);
    }
  }, [fetchUserData, token, changeProfile]);

  const handleDeleteProject = async () => {
    try {
      await axios.delete(`${apiBaseUrl}/projects/delete/${projectToDelete}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setProjectList((prevList) =>
        prevList.filter((project) => project.id !== projectToDelete)
      );
      setProjects((prevProjects) =>
        prevProjects.filter((project) => project.id !== projectToDelete)
      );
    } catch (err) {
      console.error("Error en eliminar el projecte:", err);
    } finally {
      handleClose();
    }
  };

  const handleChangePassword = async () => {
    setPasswordMessage("");

    if (passwordData.password !== passwordData.confirmPassword) {
      setPasswordMessage("Les contrasenyes no coincideixen.");
      return;
    }

    try {
      await axios.put(
        `${apiBaseUrl}/user/change-password`,
        { password: passwordData.password },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPasswordMessage("Contrasenya actualitzada correctament.");
      setPasswordData({ password: "", confirmPassword: "" });
    } catch (err) {
      console.error("Error en canviar la contrasenya:", err);
      setPasswordMessage("No s'ha pogut actualitzar la contrasenya.");
    }
  };

  const uploadImage = async (file) => {
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", preset_name);
    data.append("folder", "profile_pictures");

    try {
      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`,
        data
      );

      return response.data.secure_url;
    } catch (error) {
      console.error("Error al pujar la imatge:", error);
    }
  };

  const handleChangePicture = async (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    setUploading(true);
    setError(null);
    try {
      const urlImg = await uploadImage(file);

      await axios.put(
        `${apiBaseUrl}/user/change-profile-pic`,
        {
          img_url: urlImg,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setChangeProfile(urlImg);
    } catch (err) {
      console.error("Error al canviar la imatge de perfil", err);
    } finally {
      fetchUserData();
      setUploading(false);
    }
  };

  if (loading) {
    return <p className="text-center mt-5">Carregant...</p>;
  }

  if (error) {
    return <p className="text-danger text-center mt-5">{error}</p>;
  }

  return (
    <Container className="mt-5 mb-5">
      <Row className="justify-content-center">
        <Col md={4} className="position-relative">
          <Card className="position-sticky p-4 mb-5" style={{top:"106px", boxShadow:"10px 10px 0 #01403A"}}>
            <Card.Body>
              <Row className="align-items-center">
                <Col md={12} className="text-center">
                  <label
                    htmlFor="profile-pic-upload"
                  >
                    <img
                      src={user?.img_url || "https://via.placeholder.com/150"}
                      alt={`${user?.first_name} ${user?.last_name}`}
                      className="rounded-circle img-fluid mb-3"
                      style={{ maxWidth: "150px", width: "150px", height: "150px", objectFit:"cover", cursor:"pointer" }}
                    />
                  </label>
                  <Form.Control
                    type="file"
                    id="profile-pic-upload"
                    onChange={(e) => {
                      handleChangePicture(e);
                    }}
                    style={{ display: "none" }}
                  />
                  {uploading && (
                    <p className="text-muted mt-2">Actualitzant...</p>
                  )}
                </Col>
                <Col md={12} className="d-flex align-items-center flex-column">
                  <h3 className="text-center">
                    {user?.first_name} {user?.last_name}
                  </h3>
                  <p className="text-muted text-center">
                    <strong>Correu:</strong> {user?.email}
                  </p>
                  <Button variant="danger" onClick={logout}>
                    Sortir <i class="bi bi-box-arrow-right pr-2"></i>
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        
        <Col md={8}>
          {/* Modificar contrasenya */}
          <Card className="shadow-lg p-4 mb-5">
            <Card.Body>
              <h4>Modificar contrasenya</h4>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Nova contrasenya</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Introdueix la nova contrasenya"
                    value={passwordData.password}
                    onChange={(e) =>
                      setPasswordData({
                        ...passwordData,
                        password: e.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Confirma la contrasenya</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirma la nova contrasenya"
                    value={passwordData.confirmPassword}
                    onChange={(e) =>
                      setPasswordData({
                        ...passwordData,
                        confirmPassword: e.target.value,
                      })
                    }
                  />
                </Form.Group>
                {passwordMessage && (
                  <p
                    className={
                      passwordMessage.includes("correctament")
                        ? "text-success"
                        : "text-danger"
                    }
                  >
                    {passwordMessage}
                  </p>
                )}
                <Button
                  variant="primary"
                  onClick={handleChangePassword}
                  disabled={!passwordData.password}
                  className="px-4"
                >
                  Actualitzar contrasenya
                </Button>
              </Form>
            </Card.Body>
          </Card>
          {/* Projectes */}
          <Row className="d-flex justify-content-between align-items-center mb-4">
            <h4 className="w-auto mb-3 mb-xl-0">Els meus projectes</h4>
            <Button
              variant="secondary"
              className="me-4 w-auto px-3"
              onClick={() => navigate("/project/create")}
            >
              Afegir projecte <i class="bi bi-plus-circle ms-2"></i>
            </Button>
          </Row>
          <Row>
          {projectList.length === 0 ? (
                <p>No tens cap projecte.</p>
              ) : (
                projectList.map((project)  => (
                  
                  <Col md={4}
                    key={project.id}
                    className={`mb-5`}
                  >
                    <div className="mdl-project__it bg-white overflow-hidden pb-5 position-relative h-100">
                      <img
                        src={
                          project.img_url || "https://via.placeholder.com/100"
                        }
                        alt={project.title}
                        className="img-fluid mb-5 mb-xl-3"
                      />
                        <span className="tag mx-3 position-absolute">
                          #{project.category}
                        </span>
                        <span
                          onClick={() => navigate(`/project/${project.id}`)}
                          style={{ cursor: "pointer" }}
                        >
                          <h3 className="mx-3">{project.title}</h3>
                        </span>
                        <p className="mx-3">
                          {project.description.length > 100
                            ? `${project.description.substring(0, 100)}...`
                            : project.description}
                        </p>
                        <Button
                          variant="outline-secondary"
                          onClick={() => navigate(`/project/${project.id}/edit`)}
                          className="me-2 ms-3"
                        >
                          Editar <i class="bi bi-pencil-square ms-2"></i>
                        </Button>
                        <Button
                          variant="danger"
                          onClick={() => handleShow(project.id)}
                        >
                          Esborrar <i class="bi bi-trash3 ms-2"></i>
                        </Button>
                    </div>
                  </Col>
                ))
              )}
          </Row>
        </Col>
      </Row>
      {/* Modal per eliminar projecte */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Projecte</Modal.Title>
        </Modal.Header>
        <Modal.Body>Estàs segur que vols eliminar aquest projecte?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel·lar
          </Button>
          <Button variant="danger" onClick={handleDeleteProject}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ProfilePage;
