import React, { useEffect, useState } from "react";
import axios from "axios";
import apiBaseUrl from "../../../config/api";
import { Col, Container, Row } from "react-bootstrap";

import "../../../pages/ProjectDetail/projectdetail.scss";

function ProjectContributions({ projectId }) {
  const [contributions, setContributions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchContributions = async () => {
      try {
        const response = await axios.get(
          `${apiBaseUrl}/contributions/from/${projectId}`
        );
        setContributions(response.data.contributions);
      } catch (err) {
        setError("Error al carregar les contribucions.");
      } finally {
        setLoading(false);
      }
    };

    fetchContributions();
  }, [projectId]);

  if (loading) {
    return <div className="text-center mt-3">Carregant contribucions...</div>;
  }

  if (error) {
    return <div className="alert alert-danger mt-3">{error}</div>;
  }

  if (contributions.length === 0) {
    return (
      <Container>
        <Row>
          <Col md={12} className="alert alert-warning mt-3">
            Encara no hi ha contribucions per aquest projecte.
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <section className="mdl-project-contributions bg-white py-5">
      <Container>
        <Row>
          <Col>
            <h2 className="mb-5">Contribucions</h2>
            <Row>
            {contributions.map((contribution, index) => (
              <Col md={4} key={index} className="mb-4">
                <div className="mdl-project-contributions__it p-3 p-md-5">
                    <div className="d-flex align-items-center mb-3">
                      {!contribution.is_anonymous && contribution.user.img_url && (
                        <img
                          src={contribution.user.img_url}
                          alt={contribution.user.name}
                          className="rounded-circle me-3"
                          style={{ width: "50px", height: "50px" }}
                        />
                      )}
                      {!contribution.is_anonymous ? (
                        <p className="mb-0"><strong>Nom:</strong> {contribution.user.name}</p>
                      ) : (
                        <p className="mb-0"><strong>Usuari:</strong> Anònim</p>
                      )}
                    </div>
                    <div className="d-flex flex-column">
                      <p className="mb-1">
                        <strong>Quantitat:</strong>{" "}
                        {contribution.amount.toLocaleString()}€
                      </p>
                      {contribution.message && (
                        <p className="mb-1">
                          <strong>Missatge:</strong> {contribution.message}
                        </p>
                      )}
                      <p><strong>Data:</strong> {new Date(contribution.created_at).toLocaleDateString()}</p>
                  </div>
                </div>
              </Col>
            ))}
            </Row>
          </Col>
        </Row>
      </Container>
     
      
      
    </section>
  );
}

export default ProjectContributions;
