import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import { fixture } from "./fixture";
import { Link, useNavigate } from "react-router-dom";

import "./featuredprojects.scss";
import apiBaseUrl from "../../../config/api.js";

const FeaturedProjects = () => {
  const [projects, setProjects] = useState([]);
  const [infoFromBackend, setInfoFromBackend] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/homepage`)
      .then((response) => {
        const fetchedProjects = response.data.projects.mostVisited.slice(0, 4);
        setInfoFromBackend(true);
        setProjects(fetchedProjects);
      })
      .catch((error) => {
        setProjects(fixture.projects.mostVisited.slice(0, 4));
        console.error("Error al obtener los proyectos:", error);
      });
  }, []);

  return (
    <section className="mdl-featured-projects pt-5 pb-5">
      <Container>
        <Row className="mb-5 row align-items-center d-flex">
          <Col md={12}>
            <h2 className="text-center">
              El teu suport, el seu futur: <br />
              cada contribució compta.
            </h2>
            <p className="text-center">
              Uneix-te a la nostra missió d'impulsar projectes que marquen la
              diferència en les comunitats de tot el món.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {!infoFromBackend && (
              <h3 style={{ color: "pink" }}>Informació des de el Fixture</h3>
            )}
            <Row>
              {projects.map((project) => (
              <Col md={3} className="mb-5" key={project.id}>
                <div className="mdl-project__it bg-white overflow-hidden pb-5 position-relative h-100">
                  <img
                    src={project.img_url || "/imgs/img-intro.jpg"}
                    alt={project.title}
                    className="img-fluid mb-5 mb-md-3"
                  />
                  <span className="tag mx-3 position-absolute">#{project.category}</span>
                  <h3 className="mx-3">{project.title}</h3>
                  <p className="mx-3">
                    {project.description.length > 100
                      ? project.description.slice(0, 100) + "..."
                      : project.description}
                  </p>
                  <Link to={`/project/${project.id}`} aria-label={`Veure ${project.title}`} className="position-absolute top-0 start-0 w-100 h-100"></Link>
                </div>
              </Col>
              ))}
              <Col md={12} className="d-flex justify-content-center">
                <Button variant="primary" size="lg" style={{ width: "200px" }} onClick={()=>navigate("/projects")}>
                  Explorar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FeaturedProjects;
