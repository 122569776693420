// 20241031105204
// https://api.fundwave.es/homepage

export const fixture = {
  projects: {
    mostVisited: [
      {
        id: 18,
        title: "Prof.",
        description:
          "Quas ullam incidunt ut ipsam. Hic aut ratione ullam sunt voluptatum commodi. Excepturi fugit veritatis perspiciatis voluptatum quas.",
        financial_goal: 3381,
        actual_amount: 1649,
        img_url: null,
        category: "Negoci",
        views: 141,
        active: 1,
        created_at: "2024-10-22T21:38:42.000000Z",
        updated_at: "2024-10-22T21:38:42.000000Z",
        deadline: "2000-12-23",
        user: {
          name: "Bonita Hammes",
          img_url: null,
        },
      },
      {
        id: 12,
        title: "Prof.",
        description:
          "Eaque voluptatum dolorum in omnis rem. Fugiat ratione totam esse perspiciatis ut eum vel. Itaque aut quasi dolore.",
        financial_goal: 5051,
        actual_amount: 1408,
        img_url: null,
        category: "Mediambient",
        views: 128,
        active: 1,
        created_at: "2024-10-22T21:38:42.000000Z",
        updated_at: "2024-10-22T21:38:42.000000Z",
        deadline: "1975-05-23",
        user: {
          name: "Wilhelmine Marks",
          img_url: null,
        },
      },
      {
        id: 13,
        title: "Dr.",
        description:
          "Aperiam ratione velit numquam sint illum quod et. Quidem debitis ut dignissimos. Magni velit amet voluptatem. Iste qui est non quidem ut molestiae fuga.",
        financial_goal: 8492,
        actual_amount: 1125,
        img_url: null,
        category: "Educació",
        views: 127,
        active: 1,
        created_at: "2024-10-22T21:38:42.000000Z",
        updated_at: "2024-10-22T21:38:42.000000Z",
        deadline: "2016-06-25",
        user: {
          name: "Gladys Dach",
          img_url: null,
        },
      },
      {
        id: 11,
        title: "Prof.",
        description:
          "Dignissimos iste sunt dolorem officia necessitatibus. Voluptas deleniti dignissimos accusamus expedita. Quia temporibus quisquam quidem voluptatibus cumque unde. Est culpa ab vel.",
        financial_goal: 4199,
        actual_amount: 1851,
        img_url: null,
        category: "Educació",
        views: 122,
        active: 1,
        created_at: "2024-10-22T21:38:42.000000Z",
        updated_at: "2024-10-22T21:38:42.000000Z",
        deadline: "1991-10-25",
        user: {
          name: "Oriol Hilari",
          img_url: null,
        },
      },
      {
        id: 17,
        title: "Prof.",
        description:
          "Et voluptate et velit itaque sed quia. Vel id voluptatibus blanditiis nisi laborum ut laboriosam.",
        financial_goal: 6413,
        actual_amount: 1171,
        img_url: null,
        category: "Comunitat",
        views: 122,
        active: 1,
        created_at: "2024-10-22T21:38:42.000000Z",
        updated_at: "2024-10-22T21:38:42.000000Z",
        deadline: "1972-10-27",
        user: {
          name: "Nadia Nuñez Berrocal",
          img_url: null,
        },
      },
      {
        id: 20,
        title: "Mrs.",
        description:
          "Odit minima voluptatibus et fuga. Officia corrupti rem earum at quae sit. Occaecati unde molestiae eos amet sunt distinctio ipsum nulla.",
        financial_goal: 9868,
        actual_amount: 1320,
        img_url: null,
        category: "Comunitat",
        views: 122,
        active: 1,
        created_at: "2024-10-22T21:38:42.000000Z",
        updated_at: "2024-10-22T21:38:42.000000Z",
        deadline: "2007-07-07",
        user: {
          name: "Natalia Smitham",
          img_url: null,
        },
      },
      {
        id: 19,
        title: "Mr.",
        description:
          "Voluptatem repudiandae sint temporibus nemo. Dignissimos et aut vitae facere distinctio esse ut. Eius ut nostrum possimus soluta itaque et unde.",
        financial_goal: 6750,
        actual_amount: 1317,
        img_url: null,
        category: "Educació",
        views: 107,
        active: 1,
        created_at: "2024-10-22T21:38:42.000000Z",
        updated_at: "2024-10-22T21:38:42.000000Z",
        deadline: "1998-09-24",
        user: {
          name: "Natalia Smitham",
          img_url: null,
        },
      },
      {
        id: 7,
        title: "Dr.",
        description:
          "Impedit illum eius est iure. Rerum perspiciatis quo reiciendis. Voluptatem quas commodi et.",
        financial_goal: 7445,
        actual_amount: 1007,
        img_url: null,
        category: "ONG",
        views: 104,
        active: 1,
        created_at: "2024-10-22T21:38:42.000000Z",
        updated_at: "2024-10-22T21:38:42.000000Z",
        deadline: "1978-01-07",
        user: {
          name: "Ottis Altenwerth",
          img_url: null,
        },
      },
      {
        id: 1,
        title: "Dr.",
        description:
          "Omnis doloremque quae porro maiores atque fugit. Quis qui omnis voluptatem cumque. Maxime officiis exercitationem dicta id ratione corrupti est est. Voluptatem sed ipsa facilis distinctio nam ab et sit.",
        financial_goal: 4607,
        actual_amount: 963,
        img_url: null,
        category: "Animals",
        views: 101,
        active: 1,
        created_at: "2024-10-22T21:38:42.000000Z",
        updated_at: "2024-10-22T21:38:42.000000Z",
        deadline: "2016-06-07",
        user: {
          name: "Kristina Pfeffer",
          img_url: null,
        },
      },
      {
        id: 4,
        title: "Dr.",
        description:
          "Et est eum aut magni sed est tenetur reiciendis. Qui dicta consectetur aut quam maxime aut adipisci. Quod accusantium sit sunt minima nulla nesciunt. Saepe fugiat iste ut similique.",
        financial_goal: 1068,
        actual_amount: 1671,
        img_url: null,
        category: "Comunitat",
        views: 93,
        active: 1,
        created_at: "2024-10-22T21:38:42.000000Z",
        updated_at: "2024-10-22T21:38:42.000000Z",
        deadline: "1972-12-24",
        user: {
          name: "Wilhelmine Marks",
          img_url: null,
        },
      },
    ],
    newest: [
      {
        id: 1,
        title: "Dr.",
        description:
          "Omnis doloremque quae porro maiores atque fugit. Quis qui omnis voluptatem cumque. Maxime officiis exercitationem dicta id ratione corrupti est est. Voluptatem sed ipsa facilis distinctio nam ab et sit.",
        financial_goal: 4607,
        actual_amount: 963,
        img_url: null,
        category: "Animals",
        views: 101,
        active: 1,
        created_at: "2024-10-22T21:38:42.000000Z",
        updated_at: "2024-10-22T21:38:42.000000Z",
        deadline: "2016-06-07",
        user: {
          name: "Kristina Pfeffer",
          img_url: null,
        },
      },
      {
        id: 2,
        title: "Ms.",
        description:
          "Nesciunt facilis eos aut iste. Quis quibusdam sed ea odio quas nisi. Aut dolores rerum dicta reprehenderit maxime distinctio. Impedit saepe tenetur dolorem officiis quia dolorum.",
        financial_goal: 7154,
        actual_amount: 1991,
        img_url: null,
        category: "ONG",
        views: 73,
        active: 1,
        created_at: "2024-10-22T21:38:42.000000Z",
        updated_at: "2024-10-22T21:38:42.000000Z",
        deadline: "1974-05-10",
        user: {
          name: "Kristina Pfeffer",
          img_url: null,
        },
      },
      {
        id: 3,
        title: "Dr.",
        description:
          "Sunt totam voluptate nostrum nihil. Atque sint repellat esse architecto velit et fugiat. Nesciunt et quibusdam ut sit perferendis dolores. Necessitatibus quasi et ut quisquam numquam id.",
        financial_goal: 6661,
        actual_amount: 1519,
        img_url: null,
        category: "Educació",
        views: 58,
        active: 1,
        created_at: "2024-10-22T21:38:42.000000Z",
        updated_at: "2024-10-22T21:38:42.000000Z",
        deadline: "1989-11-16",
        user: {
          name: "Joyce Senger",
          img_url: null,
        },
      },
      {
        id: 4,
        title: "Dr.",
        description:
          "Et est eum aut magni sed est tenetur reiciendis. Qui dicta consectetur aut quam maxime aut adipisci. Quod accusantium sit sunt minima nulla nesciunt. Saepe fugiat iste ut similique.",
        financial_goal: 1068,
        actual_amount: 1671,
        img_url: null,
        category: "Comunitat",
        views: 93,
        active: 1,
        created_at: "2024-10-22T21:38:42.000000Z",
        updated_at: "2024-10-22T21:38:42.000000Z",
        deadline: "1972-12-24",
        user: {
          name: "Wilhelmine Marks",
          img_url: null,
        },
      },
      {
        id: 5,
        title: "Mr.",
        description:
          "Nesciunt omnis qui eligendi nobis consequatur. Harum aspernatur deleniti delectus aut quia et. Ea enim consequuntur voluptatem magnam incidunt blanditiis enim. Sed sed quasi quia natus. Vel veniam repudiandae hic.",
        financial_goal: 3673,
        actual_amount: 982,
        img_url: null,
        category: "Comunitat",
        views: 64,
        active: 1,
        created_at: "2024-10-22T21:38:42.000000Z",
        updated_at: "2024-10-22T21:38:42.000000Z",
        deadline: "2010-06-03",
        user: {
          name: "Wilhelmine Marks",
          img_url: null,
        },
      },
    ],
  },
};
