import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Form } from "react-bootstrap";

import { Link, useNavigate } from "react-router-dom";
import { ProjectContext } from "../../../context/ProjectContext";
import { UserContext } from "../../../context/UserContext";

import "./navbar.scss";

const NavBar = () => {
  const { categories } = useContext(ProjectContext);
  const { user, logout } = useContext(UserContext);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchTerm.trim() !== "") {
      navigate(
        `/projects/search-results?search=${encodeURIComponent(searchTerm)}`
      );
    }
    setSearchTerm("");
  };

  return (
    <Navbar expand="lg" className="header bg-body-primary sticky-top">
      <Container fluid>
        <Navbar.Brand as={Link} to="/">
          <img
            src="/imgs/logo-fundwave.png"
            alt="Logo fundwave"
            className="img-fluid"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0" navbarScroll>
            <Nav.Link as={Link} to="/projects">
              Projectes
            </Nav.Link>
            <NavDropdown title="Categories" id="navbarScrollingDropdown">
              {categories.map((categoryObj) => (
                <NavDropdown.Item
                  as={Link}
                  to={`/projects?category=${encodeURIComponent(
                    categoryObj.category
                  )}`}
                  key={categoryObj.category_id}
                >
                  {categoryObj.category}
                </NavDropdown.Item>
              ))}
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/projects">
                Veure tot
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/about">
              Qui som
            </Nav.Link>
            <Nav.Link as={Link} to="/project/create">
              <b>Publicar</b>
            </Nav.Link>
          </Nav>
          <Nav className="ms-auto">
            {user ? (
              <>
                <Button
                  variant="link"
                  className="text-primary ms-3"
                  onClick={() => navigate("/profile")}
                >
                  <i className="bi bi-person-circle px-2"></i>
                  Hola, {user.first_name}
                </Button>
                <Button
                  variant="outline-primary"
                  className="ms-0 ms-lg-3 mb-3 mb-lg-0"
                  onClick={logout}
                >
                   Tancar Sessió <i className="bi bi-box-arrow-right pr-2"></i>
                </Button>
              </>
            ) : (
              <Link to="/login" className="btn btn-outline-secondary">
                <i className="bi bi-person-circle pe-2"></i> Accedir
              </Link>
            )}
          </Nav>
          <Form className="d-flex mx-lg-2 position-relative" onSubmit={handleSearch}>
            <Form.Control
              type="search"
              placeholder="Cercar projectes"
              className="search-input"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button type="submit" variant="secondary" className="position-absolute end-0">
              Cercar
            </Button>
          </Form>
          
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
