import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Alert,
} from "react-bootstrap";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ProjectContext } from "../../context/ProjectContext";
import { UserContext } from "../../context/UserContext";
import apiBaseUrl from "../../config/api.js";
import { cloud_name, preset_name } from "../../config/cloudinary.js";

const ProjectEdit = () => {
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { categories } = useContext(ProjectContext); // Obtenir les categories del context
  const { token } = useContext(UserContext); // Obtenir el token de l'usuari
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    financial_goal: "",
    category_id: "", // Utilitza `category_id` en lloc de `category`
    deadline: "",
    img: null,
  });
  const [success, setSuccess] = useState(null);
  const [alert, setAlert] = useState({ show: false, type: "", message: "" }); // Estat per a les alertes
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/projects/${id}`);
        setProject(response.data[0]);

        setFormData({
          title: response.data[0].title,
          description: response.data[0].description,
          financial_goal: response.data[0].financial_goal,
          category_id: response.data[0].category_id,
          deadline: response.data[0].deadline,
        });
      } catch (err) {
        setError("Error en carregar les dades del projecte.");
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData({ ...formData, img: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const uploadImage = async (file) => {
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", preset_name);
    data.append("folder", "projects_assets");
    setLoading(true);
    try {
      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`,
        data
      );
      return response.data.secure_url;
    } catch (error) {
      console.error("Error al pujar la imatge:", error);
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError(null);
    setSuccess(null);

    if (
      !formData.title ||
      !formData.description ||
      !formData.financial_goal ||
      !formData.category_id ||
      !formData.deadline
    ) {
      setAlert({
        show: true,
        type: "danger",
        message: "Si us plau, completa tots els camps obligatoris.",
      });
      return;
    }

    try {
      setLoading(true);

      const projectData = {
        title: formData.title,
        description: formData.description,
        financial_goal: formData.financial_goal,
        category_id: formData.category_id,
        deadline: formData.deadline,
        imgUrl: "",
      };

      if (formData.img) {
        const urlImg = await uploadImage(formData.img);
        projectData.imgUrl = urlImg;
      } else {
        projectData.imgUrl = project.img_url;
      }

      await axios.put(
        `${apiBaseUrl}/projects/update/${project.id}`,
        projectData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAlert({
        show: true,
        type: "success",
        message: "Projecte modificat amb èxit. Redirigint...",
      });

      // Redirigir després de 2 segons
      setTimeout(() => {
        navigate(`/project/${project.id}`);
      }, 2000);
    } catch (err) {
      console.error("Error al modificar el projecte:", err);
      setAlert({
        show: true,
        type: "danger",
        message:
          "Hi ha hagut un problema al modificar el projecte. Torna-ho a intentar.",
      });
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="text-center mt-5">Carregant...</div>;
  }

  if (error) {
    return <div className="alert alert-danger mt-5">{error}</div>;
  }

  if (!project) {
    return <div className="alert alert-warning mt-5">Projecte no trobat.</div>;
  }

  return (
    <Container className="mt-5 mb-5">
      {/* Alerta flotant */}
      {alert.show && (
        <Alert
          variant={alert.type}
          className="position-fixed top-0 start-50 translate-middle-x mt-3 text-center w-50"
          style={{ zIndex: 1050 }}
          onClose={() => setAlert({ show: false })}
          dismissible
        >
          {alert.message}
        </Alert>
      )}

      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="shadow-lg">
            <Card.Body className="p-4 p-md-5">
              <h3 className="mb-4 text-center">Editar Projecte</h3>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Títol</Form.Label>
                      <Form.Control
                        type="text"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Objectiu financer (€)</Form.Label>
                      <Form.Control
                        type="number"
                        name="financial_goal"
                        value={formData.financial_goal}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Categoria</Form.Label>
                      <Form.Select
                        name="category_id"
                        value={formData.category_id}
                        onChange={handleChange}
                      >
                        <option value="">Selecciona una categoria</option>
                        {categories.map((category) => (
                          <option
                            key={category.category_id}
                            value={category.category_id}
                          >
                            {category.category}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Data límit</Form.Label>
                      <Form.Control
                        type="date"
                        name="deadline"
                        value={formData.deadline}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Imatge del projecte</Form.Label>
                      <Form.Control
                        type="file"
                        name="img"
                        accept="image/*"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Descripció</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Button
                      type="submit"
                      variant="primary"
                      className="w-100"
                      disabled={loading}
                    >
                      {loading ? "Modificant..." : "Modificar Projecte"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ProjectEdit;
