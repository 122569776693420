import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export const LegalCookies = () => {
  return (
    <div>
      <section className="mdl-hero-in">
        <Container>
          <Row>
            <Col md={12} className="col-md-12 d-flex justify-content-center">
              <h1 className="title">Política de cookies</h1>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="mdl-hero-in bg-white">
        <Container style={{ maxWidth: "800px" }}>
          <Row>
            <Col
              md={12}
              className="d-flex justify-content-center flex-column align-items-start"
            >
              <p>
                Aquesta pol&iacute;tica de cookies t&eacute; com a objectiu
                informar-te de manera clara i completa sobre les cookies que
                utilitzem a la web de <strong>FundWave</strong>.
              </p>
              <h2>
                <strong>1. Qu&egrave; s&oacute;n les cookies?</strong>
              </h2>
              <p>
                Les cookies s&oacute;n petits arxius de text que s'emmagatzemen
                en el teu dispositiu (ordinador, tel&egrave;fon m&ograve;bil,
                tauleta, etc.) quan accedeixes a la nostra p&agrave;gina web.
                Aquestes cookies ens permeten recon&egrave;ixer la teva
                navegaci&oacute; a trav&eacute;s de la web i millorar la teva
                experi&egrave;ncia d'usuari.
              </p>
              <h2>
                <strong>2. Tipus de cookies que utilitzem</strong>
              </h2>
              <p>
                Les cookies poden ser classificades en funci&oacute; de la seva
                durada, la seva finalitat i l'entitat que les gestiona. A
                continuaci&oacute;, et detallem els tipus de cookies que
                utilitzem a <strong>FundWave</strong>:
              </p>
              <ul>
                <li>
                  <p>
                    <strong>Cookies pr&ograve;pies</strong>: S&oacute;n aquelles
                    que s'envien al teu dispositiu des dels nostres equips o
                    dominis, i s&oacute;n gestionades directament per nosaltres.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Cookies de tercers</strong>: S&oacute;n aquelles que
                    s'envien al teu dispositiu des d'un equip o domini que no
                    &eacute;s gestionat per nosaltres, sin&oacute; per una altra
                    entitat (per exemple, per analitzar el tr&agrave;nsit web o
                    mostrar publicitat personalitzada).
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Cookies de sessi&oacute;</strong>: Aquestes cookies
                    s&oacute;n temporals i s'emmagatzemen a la mem&ograve;ria
                    del teu dispositiu fins que finalitzes la sessi&oacute; de
                    navegaci&oacute;. S&oacute;n utilitzades per identificar-te
                    durant la sessi&oacute; i per facilitar el proc&eacute;s de
                    navegaci&oacute;.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Cookies persistents</strong>: S&oacute;n aquelles
                    que es mantenen emmagatzemades al teu dispositiu durant un
                    per&iacute;ode determinat, fins que les elimines manualment
                    o fins que el seu termini de caducitat expira.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Cookies t&egrave;cniques</strong>: S&oacute;n
                    necess&agrave;ries per al funcionament de la web i per
                    permetre't navegar i utilitzar les seves funcions
                    b&agrave;siques, com l'acc&eacute;s a &agrave;rees
                    restringides o la gesti&oacute; de la teva sessi&oacute;.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Cookies d'an&agrave;lisi</strong>: S&oacute;n
                    utilitzades per recollir informaci&oacute; de manera
                    anonimizada sobre l'&uacute;s de la web per part dels
                    usuaris (per exemple, el nombre de visites o les
                    p&agrave;gines m&eacute;s consultades), amb la finalitat de
                    millorar els continguts i l'&uacute;s de la web.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Cookies publicit&agrave;ries</strong>: Aquestes
                    cookies permeten gestionar de manera efica&ccedil; els
                    espais publicitaris de la web. S&oacute;n utilitzades per
                    mostrar publicitat adaptada als interessos dels usuaris
                    basant-se en la seva navegaci&oacute;.
                  </p>
                </li>
              </ul>
              <h2>
                <strong>3. Finalitats de les cookies</strong>
              </h2>
              <p>
                Les cookies que utilitzem a <strong>FundWave</strong> tenen les
                seg&uuml;ents finalitats:
              </p>
              <ul>
                <li>
                  <p>
                    <strong>Millorar l'experi&egrave;ncia d'usuari</strong>: Les
                    cookies ens ajuden a recordar les teves prefer&egrave;ncies
                    i a personalitzar la teva experi&egrave;ncia de
                    navegaci&oacute;.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      An&agrave;lisi de tr&agrave;nsit i rendiment
                    </strong>
                    : Utilitzem cookies d'an&agrave;lisi per obtenir dades
                    estad&iacute;stiques sobre com els usuaris interactuen amb
                    la nostra web i per millorar els seus continguts i
                    funcionalitats.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Gesti&oacute; de la publicitat</strong>: Algunes
                    cookies s'utilitzen per gestionar els anuncis que mostrem
                    als usuaris i per analitzar el rendiment d'aquesta
                    publicitat.
                  </p>
                </li>
              </ul>
              <h2>
                <strong>4. Consentiment per l'&uacute;s de cookies</strong>
              </h2>
              <p>
                En accedir a la nostra web, t'informem sobre l'&uacute;s de
                cookies mitjan&ccedil;ant una finestra emergent on pots acceptar
                l'&uacute;s de les cookies o configurar la teva
                prefer&egrave;ncia. Si continues navegant pel lloc web sense
                modificar les configuracions de cookies, entendrem que acceptes
                l'&uacute;s de cookies per part nostra.
              </p>
              <p>
                Tamb&eacute; pots canviar les teves prefer&egrave;ncies de
                cookies en qualsevol moment a trav&eacute;s de la
                configuraci&oacute; del teu navegador, tal com s'indica
                m&eacute;s avall.
              </p>
              <h2>
                <strong>5. Com gestionar les cookies</strong>
              </h2>
              <p>
                Pots configurar el teu navegador perqu&egrave; et notifiqui quan
                rebi cookies o perqu&egrave; desactivi l'&uacute;s de cookies.
                El proc&eacute;s per gestionar les cookies varia segons el
                navegador, per&ograve; normalment es troba a les opcions o
                prefer&egrave;ncies de privacitat o seguretat.
              </p>
              <p>
                A continuaci&oacute;, et proporcionem els enlla&ccedil;os a les
                opcions de configuraci&oacute; de cookies dels navegadors
                m&eacute;s populars:
              </p>
              <ul>
                <li>
                  <a
                    href="https://support.google.com/chrome/answer/95647?hl=en&amp;co=GENIE.Platform%3DDesktop"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Google Chrome
                  </a>
                </li>
                <li>
                  <a
                    href="https://support.mozilla.org/ca/kb/activar-i-desactivar-cookies"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Mozilla Firefox
                  </a>
                </li>
                <li>
                  <a
                    href="https://support.apple.com/kb/ph21411?locale=ca_ES"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Safari
                  </a>
                </li>
                <li>
                  <a
                    href="https://support.microsoft.com/ca-es/microsoft-edge/privacy-faq-microsoft-edge"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Microsoft Edge
                  </a>
                </li>
              </ul>
              <p>
                Si desactives les cookies, potser no puguis gaudir de totes les
                funcionalitats de la nostra web de manera &ograve;ptima.
              </p>
              <h2>
                <strong>
                  6. Actualitzacions de la pol&iacute;tica de cookies
                </strong>
              </h2>
              <p>
                Aquesta pol&iacute;tica de cookies pot ser actualitzada
                peri&ograve;dicament per adaptar-la a les novetats legislatives,
                t&egrave;cniques o als serveis que oferim. T'informarem de
                qualsevol canvi a trav&eacute;s d'una notificaci&oacute; a la
                web o mitjan&ccedil;ant altres canals de comunicaci&oacute;.
              </p>
              <h2>
                <strong>7. Contacte</strong>
              </h2>
              <p>
                Si tens alguna pregunta sobre la nostra pol&iacute;tica de
                cookies o sobre com gestionem les cookies, pots contactar amb
                nosaltres a trav&eacute;s del correu electr&ograve;nic
                seg&uuml;ent:{" "}
                <a href="mailto:info@fundwave.es">info@fundwave.es</a>.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};
