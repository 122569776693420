import React, { useContext } from "react";
// import { Button } from "react-bootstrap";
import Marquee from "react-fast-marquee";
import { ProjectContext } from "../../../context/ProjectContext"; // Importar el contexto
import "./index.scss";
import { Link } from "react-router-dom";

const HeroProjects = () => {
  const { projects, loading, error } = useContext(ProjectContext); // Accede al contexto global

  return loading ? (
    <p>Cargando nombres...</p>
  ) : error ? (
    <p className="text-danger">{error}</p>
  ) : (
    <Marquee className="mb-3">
      {projects.map((project) => (
        <div className="marquee-item bg-white position-relative" key={project.id}>
          <img
            src={project.img_url || "/imgs/img-intro.jpg"}
            alt={project.title}
            className="img-fluid"
          />
         <span className="tag d-flex" style={{maxWidth:"fit-content"}}>#{project.category}</span>
         <h3>{project.title}</h3>
         <Link to={`/project/${project.id}`} aria-label={`Veure ${project.title}`} className="text-decoration-none position-absolute w-100 h-100 top-0 start-0"></Link>
        </div>
      ))}
    </Marquee>
  );
};

export default HeroProjects;
