import React from "react";
import Hero from "../../components/Home/Hero";
import Intro from "../../components/Home/Intro";
import FeaturedProjects from "../../components/Home/FeaturedProjects";

export const Home = () => {
  return (
    <div>
      <Hero />
      <Intro />
      <FeaturedProjects />
    </div>
  );
};
