import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { fixture } from "./fixture";
import Marquee from "react-fast-marquee";
import { ProjectContext } from "../../../context/ProjectContext";
import apiBaseUrl from "../../../config/api";
import { Link } from "react-router-dom";

import "./index.scss";

const HeroProjects = () => {
  const { projects, loading, error } = useContext(ProjectContext);
  const [localProjects, setLocalProjects] = useState([]);
  const [infoFromBackend, setInfoFromBackend] = useState(false);

  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/homepage`)
      .then((response) => {
        const fetchedProjects = response.data.projects.mostVisited.slice(0, 4);
        setInfoFromBackend(true);
        setLocalProjects(fetchedProjects);
      })
      .catch((error) => {
        setLocalProjects(fixture.projects.mostVisited.slice(0, 4));
        console.error("Error al obtener los proyectos:", error);
      });
  }, []);

  if (loading) {
    return <p>Cargando nombres...</p>;
  }

  if (error) {
    return <p className="text-danger">{error}</p>;
  }

  return (
    <Marquee className="mb-3" direction="right">
      {(infoFromBackend ? localProjects : projects).map((project) => (
        <div className="marquee-item bg-white position-relative" key={project.id}>
          <img
            src={project.img_url || "/imgs/img-intro.jpg"}
            alt={project.title}
            className="img-fluid"
          />
          <span className="tag d-flex" style={{maxWidth:"fit-content"}}>#{project.category}</span>
          <h3>{project.title}</h3>
          <Link to={`/project/${project.id}`} aria-label={`Veure ${project.title}`}  className="text-decoration-none position-absolute w-100 h-100 top-0 start-0"></Link>
        </div>
      ))}
    </Marquee>
  );
};

export default HeroProjects;
