import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
import apiBaseUrl from "../../config/api";

// Crear el context
export const UserContext = createContext();

// Hook per utilitzar el context
export const useUser = () => useContext(UserContext);

// Proveïdor del context
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Sincronitzar amb localStorage quan es carrega el component
  useEffect(() => {
    const savedToken = localStorage.getItem("token");
    const savedUser = localStorage.getItem("user");

    if (savedToken && savedUser) {
      setToken(savedToken);
      setUser(JSON.parse(savedUser));
    } else {
      // Netejar el localStorage. NO ES POT USAR logout() PERQUE ÉS ASYNC.
      localStorage.removeItem("token");
      localStorage.removeItem("user");
    }
  }, []);

  // Login: gestiona l'autenticació i retorna el token i usuari
  const login = async (email, password) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(`${apiBaseUrl}/user/login`, {
        email,
        password,
      });
      
      // La resposta té l'estructura { user: {...}, token: "..." }
      const { user, token } = response.data;

      // Sincronitzar l'estat
      setUser(user);
      setToken(token);

      // Guardar al localStorage
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);

      return response;
    } catch (err) {
      setError("Error en iniciar sessió. Verifica les teves credencials.");
      console.error("ERROR: ",err);
    } finally {
      setLoading(false);
    }
  };

  // Logout: neteja tant l'estat com el localStorage i elimina el token de la BBDD
  const logout = async () => {
    try {
      token &&
        (await axios.delete(`${apiBaseUrl}/user/logout`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }));

      setUser(null);
      setToken(null);

      // Netejar el localStorage
      localStorage.removeItem("token");
      localStorage.removeItem("user");
    } catch (err) {
      console.error("Error al fer logout: ", err);
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        token,
        loading,
        error,
        login,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
