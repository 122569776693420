import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export const LegalPrivacy = () => {
  return (
    <div>
      <section className="mdl-hero-in">
        <Container>
          <Row>
            <Col md={12} className="col-md-12 d-flex justify-content-center">
              <h1 className="title">Política de privacitat</h1>
            </Col>
          </Row>
        </Container>
        
      </section>
      
      <section className="mdl-hero-in bg-white">
        <Container style={{ maxWidth: '800px' }}>
          <Row>
            <Col md={12} className="d-flex justify-content-center flex-column align-items-start">
            <p>A FundWave ens prenem la privacitat de les teves dades personals molt seriosament. Aquesta pol&iacute;tica de privacitat explica com recollim, utilitzem, emmagatzemem i protegem la teva informaci&oacute; personal quan utilitzes la nostra plataforma de crowdfunding.</p>
            <h2><strong>1. Responsable del tractament de les dades</strong></h2>
            <p>El responsable del tractament de les dades personals recollides a trav&eacute;s de la nostra web &eacute;s:</p>
            <ul>
              <li><strong>Nom de l'empresa:</strong> FundWave</li>
              <li><strong>NIF/CIF:</strong> B23123654</li>
              <li><strong>Adreça:</strong> C/ XXXX, XX, Barcelona, Espanya</li>
              <li><strong>Correu electrònic:</strong> <a href="mailto:info@fundwave.es">info@fundwave.es</a></li>
              <li><strong>Telèfon:</strong> +34 635 273 847</li>
            </ul>
            <h2><strong>2. Dades que recollim</strong></h2>
            <p>Recollim dades personals quan et registres, utilitzes els nostres serveis o interactues amb la nostra web. Aquestes dades poden incloure:</p>
            <ul>
            <li><strong>Dades identificatives</strong>: Nom, cognoms, adre&ccedil;a, correu electr&ograve;nic, tel&egrave;fon, etc.</li>
            <li><strong>Dades de pagament</strong>: En cas que facis aportacions a trav&eacute;s de la nostra plataforma de crowdfunding, recollirem dades de la teva targeta banc&agrave;ria o d'altres m&egrave;todes de pagament.</li>
            <li><strong>Dades de navegaci&oacute;</strong>: Adreces IP, informaci&oacute; sobre el navegador i sistema operatiu, com tamb&eacute; dades de la teva interacci&oacute; amb la web (per exemple, quan accedeixes a les p&agrave;gines de la nostra web, el temps que passes a cada p&agrave;gina, etc.).</li>
            <li><strong>Dades de perfil</strong>: Si tens un compte a FundWave, podem recollir informaci&oacute; sobre els teus projectes de crowdfunding, prefer&egrave;ncies, historial de contribucions, etc.</li>
            </ul>
            <h2><strong>3. Finalitats del tractament</strong></h2>
            <p>Les dades personals que recollim seran utilitzades per a les seg&uuml;ents finalitats:</p>
            <ul>
            <li><strong>Gesti&oacute; de comptes d'usuari</strong>: Crear i gestionar el teu compte a la nostra plataforma.</li>
            <li><strong>Realitzaci&oacute; de transaccions</strong>: Gestionar les aportacions i les recompenses en els projectes de crowdfunding.</li>
            <li><strong>Comunicar-nos amb tu</strong>: Enviar-te informaci&oacute; important sobre la teva participaci&oacute; en la plataforma, com actualitzacions de projectes o respostes a consultes.</li>
            <li><strong>Millora de la nostra plataforma</strong>: Analitzar l'&uacute;s de la nostra web per millorar els serveis i adaptar-los a les teves prefer&egrave;ncies.</li>
            <li><strong>Compliment legal</strong>: Complir amb les obligacions legals que ens afecten com a responsables del tractament de dades.</li>
            </ul>
            <h2><strong>4. Base legal per al tractament</strong></h2>
            <p>La base legal per al tractament de les teves dades personals &eacute;s:</p>
            <ul>
            <li><strong>Execuci&oacute; d'un contracte</strong>: El tractament de les dades &eacute;s necessari per a l'execuci&oacute; dels serveis sol&middot;licitats (per exemple, la creaci&oacute; d'un compte o la realitzaci&oacute; d'una aportaci&oacute;).</li>
            <li><strong>Consentiment</strong>: Quan ens proporcionis dades de forma volunt&agrave;ria per a determinades finalitats (com l'enviament de comunicacions comercials, si escau).</li>
            <li><strong>Compliment d'obligacions legals</strong>: Per a complir amb la normativa aplicable (com la legislaci&oacute; fiscal o de protecci&oacute; de dades).</li>
            </ul>
            <h2><strong>5. Destinataris de les teves dades</strong></h2>
            <p>Les teves dades poden ser compartides amb tercers en els seg&uuml;ents casos:</p>
            <ul>
            <li><strong>Prove&iuml;dors de serveis</strong>: Podem compartir les teves dades amb prove&iuml;dors de serveis externs que ens ajuden a operar la nostra plataforma (com per exemple, processadors de pagaments, serveis d'allotjament web, etc.).</li>
            <li><strong>Autoritats competents</strong>: Si &eacute;s necessari per complir amb una obligaci&oacute; legal (per exemple, en cas de requeriments fiscals o judicials).</li>
            </ul>
            <h2><strong>6. Transfer&egrave;ncies internacionals de dades</strong></h2>
            <p>Alguns dels nostres prove&iuml;dors de serveis poden estar ubicats fora de la Uni&oacute; Europea. En aquests casos, ens assegurem que les teves dades estiguin protegides mitjan&ccedil;ant les garanties adequades, com ara cl&agrave;usules contractuals tipus o l'&uacute;s de plataformes certificades per l'escut de privacitat UE-EEUU, sempre que sigui aplicable.</p>
            <h2><strong>7. Drets de l'usuari</strong></h2>
            <p>Tens els seg&uuml;ents drets respecte a les teves dades personals:</p>
            <ul>
            <li><strong>Dret d'acc&eacute;s</strong>: Pots sol&middot;licitar informaci&oacute; sobre les teves dades personals que estem tractant.</li>
            <li><strong>Dret de rectificaci&oacute;</strong>: Pots sol&middot;licitar la correcci&oacute; de dades personals incorrectes o incompletes.</li>
            <li><strong>Dret de supressi&oacute;</strong>: Pots sol&middot;licitar l'eliminaci&oacute; de les teves dades personals, sempre que no siguin necess&agrave;ries per a les finalitats per les quals van ser recollides.</li>
            <li><strong>Dret d'oposici&oacute;</strong>: Pots oposar-te al tractament de les teves dades per motius relacionats amb la teva situaci&oacute; personal.</li>
            <li><strong>Dret a la limitaci&oacute; del tractament</strong>: Pots sol&middot;licitar la limitaci&oacute; del tractament de les teves dades en determinades circumst&agrave;ncies.</li>
            <li><strong>Dret a la portabilitat de les dades</strong>: Pots sol&middot;licitar la transfer&egrave;ncia de les teves dades a un altre responsable del tractament, en determinades condicions.</li>
            </ul>
            <p>Per exercir aquests drets, pots contactar-nos a trav&eacute;s de <a href="mailto:info@fundwave.es">info@fundwave.es</a>.</p>
            <h2><strong>8. Conservaci&oacute; de les dades</strong></h2>
            <p>Les teves dades personals seran conservades durant el temps necessari per a complir amb les finalitats per les quals van ser recollides, aix&iacute; com per complir amb les obligacions legals aplicables. Per exemple, les dades relacionades amb transaccions de crowdfunding poden ser conservades per un per&iacute;ode de 5 anys per motius fiscals.</p>
            <h2><strong>9. Mesures de seguretat</strong></h2>
            <p>A <a href="info@fundwave.es">FundWave</a> implementem mesures de seguretat t&egrave;cniques i organitzatives adequades per protegir les teves dades personals contra l'acc&eacute;s no autoritzat, la divulgaci&oacute;, la p&egrave;rdua o la destrucci&oacute;. No obstant aix&ograve;, cap sistema de transmissi&oacute; per internet ni sistema de emmagatzematge electr&ograve;nic &eacute;s 100% segur, i tot i les nostres mesures, no podem garantir la seguretat absoluta.</p>
            <h2><strong>10. Canvis en la pol&iacute;tica de privacitat</strong></h2>
            <p><strong>FundWave</strong> es reserva el dret de modificar aquesta pol&iacute;tica de privacitat en qualsevol moment. Quan es produeixi un canvi significatiu, t'informarem a trav&eacute;s de la nostra web o per altres mitjans de contacte.</p>
            <h2><strong>11. Contacte</strong></h2>
            <p>Si tens qualsevol dubte sobre aquesta pol&iacute;tica de privacitat o sobre el tractament de les teves dades personals, pots contactar amb nosaltres a trav&eacute;s del seg&uuml;ent correu electr&ograve;nic: <a href="mailto:info@fundwave.es">info@fundwave.es</a>.</p>

            </Col>
          </Row>
        </Container>
      </section>
      
    </div>
  );
};
