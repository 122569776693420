import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import apiBaseUrl from "../../config/api";
import Contribute from "../../components/Profile/Contribute";
import ProjectContributions from "../../components/Profile/ProjectContributions";
import { Col, Container, Row } from "react-bootstrap";
import './projectdetail.scss'

function ProjectDetails() {
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/projects/${id}`);
        setProject(response.data[0]);
      } catch (err) {
        setError("Error fetching project data");
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, [id]);

  if (loading) {
    return <div className="text-center mt-5">Carregant...</div>;
  }

  if (error) {
    return <div className="alert alert-danger mt-5">{error}</div>;
  }

  if (!project) {
    return <div className="alert alert-warning mt-5">Project not found</div>;
  }

  const porcentaje = (project.actual_amount / project.financial_goal) * 100;

  return (
    <>
    <section className="py-5">
      <Container>
        <Row className="mb-5">
          <Col md={8} className="mdl-project-single__desc position-relative">
            <span 
              className="tag position-absolute start-10 top-10"
              style={{top:"10px", right:"20px"}}
            >#{project.category}</span>
            <img
              src={project.img_url}
             className="mb-5 img-fluid w-100"
              alt={project.title}
            />
            <h1>{project.title}</h1>
            <p>{project.description}</p>
          </Col>
          <Col md={4}>
            <div className="mdl-project-single__info p-3 p-md-5 bg-white d-flex flex-column sticky-top">
            <div className="progress" style={{ height: '20px' }}>
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${porcentaje}%` }}
                aria-valuenow={porcentaje}
                aria-valuemin="0"
                aria-valuemax="100"
                >
                  <span className="progress-text">{Math.round(porcentaje)}%</span>
                </div>
              </div>
              <p>
                <strong>Objectiu: </strong>
                {project.financial_goal.toLocaleString()}€
              </p>
              <p>
                <strong>Quantitat actual: </strong>
                {project.actual_amount.toLocaleString()}€
              </p>
              {/*<p>
                <strong>Estat:</strong> {project.status}
              </p>*/}
              <p>
                <strong>Data límit:</strong>{" "}
                {new Date(project.deadline).toLocaleDateString()}
              </p>
              <div className="d-flex align-items-center">
                <img
                  src={project.user.img_url}
                  alt={project.user.name}
                  className="rounded-circle me-3"
                  style={{ width: "50px", height: "50px" }}
                />
                <p className="mb-0">
                  <strong>Propietari:</strong><br /> {project.user.name}
                </p>
              </div>
              <Contribute projectId={id} />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    <ProjectContributions projectId={id} />
    
    </>
  );
}

export default ProjectDetails;
