import React, { useState } from "react";
import { Col, Container, Row, Form, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import apiBaseUrl from "../../config/api.js";

// Esquema de validación con Yup
const validationSchema = Yup.object({
  firstName: Yup.string().required("El nom és obligatori."),
  lastName: Yup.string().required("Els cognoms són obligatoris."),
  email: Yup.string()
    .email("Correu electrònic no vàlid.")
    .required("El correu electrònic és obligatori."),
  message: Yup.string().required("El missatge no pot estar buit."),
  policy: Yup.boolean()
    .oneOf([true], "Has d'acceptar la política de privacitat.")
    .required("Has d'acceptar la política de privacitat."),
});

export const Contact = () => {
  const [showModal, setShowModal] = useState(false);

  const handleCommunication = async (values) => {
    try {

      const headers = {
        'Content-Type': 'application/json', 
        'Access-Control-Allow-Origin': '*',
      };

      await axios.post(`${apiBaseUrl}/communication/store`, {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        message: values.message,
        privacy_policy: values.policy,
      },
      {headers}
    );
      setShowModal(true);
    } catch (error) {
      alert("Ha ocurrit un error en el enviament del formulari.");
    }
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <div className="mdl-hero-in">
        <Container>
          <Row>
            <Col md={12} className="col-xl-12 d-flex justify-content-center">
              <h1 className="title">Contacte</h1>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bg-white py-5">
        <Container className="" style={{ maxWidth: "800px" }}>
          <Row>
            <Col>
              <h2 className="text-center w-100 mb-5">
                Posa't en contacte amb nosaltres!
                <br />
                Et donarem resposta el més aviat possible.
              </h2>
            </Col>
            <Formik
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                    message: "",
                    policy: false,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleCommunication}
                >
                  {({ handleSubmit, handleChange, values, touched, errors }) => (
                    <Form className="w-100" noValidate onSubmit={handleSubmit}>
                      <Row>
                        <Col md={6} className="mb-3">
                          <Form.Group controlId="communicationFirstName">
                            <Form.Label>
                              Nom <sup>*</sup>
                            </Form.Label>
                            <Field
                              type="text"
                              name="firstName"
                              placeholder="Introdueix el teu nom"
                              className={`form-control ${
                                touched.firstName && errors.firstName
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name="firstName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6} className="mb-3">
                          <Form.Group controlId="communicationLastName">
                            <Form.Label>
                              Cognoms <sup>*</sup>
                            </Form.Label>
                            <Field
                              type="text"
                              name="lastName"
                              placeholder="Introdueix els teus cognoms"
                              className={`form-control ${
                                touched.lastName && errors.lastName
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name="lastName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6} className="mb-3">
                          <Form.Group controlId="communicationEmail">
                            <Form.Label>
                              Correu electrònic <sup>*</sup>
                            </Form.Label>
                            <Field
                              type="email"
                              name="email"
                              placeholder="Introdueix el teu correu electrònic"
                              className={`form-control ${
                                touched.email && errors.email ? "is-invalid" : ""
                              }`}
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-feedback"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className="mb-3">
                          <Form.Group controlId="communicationMessage">
                            <Form.Label>
                              Missatge <sup>*</sup>
                            </Form.Label>
                            <Field
                              as="textarea"
                              name="message"
                              rows={4}
                              placeholder="Escriu el teu missatge"
                              className={`form-control ${
                                touched.message && errors.message
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name="message"
                              component="div"
                              className="invalid-feedback"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Form.Group controlId="communicationPolicy">
                        <Field
                          type="checkbox"
                          name="policy"
                          className={`form-check-input ${
                            touched.policy && errors.policy ? "is-invalid" : ""
                          }`}
                        />
                        <Form.Label className="mx-1">
                          He llegit i accepto la{" "}
                          <Link
                            to="/legal-privacy"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label={`Visitar política de privacitat`} 
                          >
                            política de privacitat
                          </Link>
                          .
                        </Form.Label>
                        <ErrorMessage
                          name="policy"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Form.Group>
                      <Row>
                        <Col
                          md={12}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <Button
                            variant="primary"
                            type="submit"
                            className="mt-2"
                            style={{ width: "200px" }}
                          >
                            Enviar
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
            </Formik>
          </Row>
        </Container>
      </div>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleCloseModal}
      >
        <Modal.Body className="p-xl-5 text-center">
          <Button
            className="position-absolute right-0 top-0"
            variant=""
            style={{ right: "0" }}
            onClick={handleCloseModal}
          >
            <i className="bi bi-x-circle"></i>
          </Button>
          <h2 className="">Gràcies per contactar!</h2>
          <p>
            El teu missatge s'ha enviat correctament. Ens posarem en contacte
            amb tu aviat.
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};
